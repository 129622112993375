import ApButton from 'common/ApButton/ApButton';
import ApReactTable from 'common/ApReactTable/ApReactTable';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import React, { useEffect, useState } from 'react';
import api from 'services/Api/Api';
import { errorPopper, tr } from 'services/Helpers/Helpers';
import MaterialCertificateEdit from './MaterialCertificateEdit';

const MaterialCertificates = () => {
    const [loading, setLoading] = useState(false);
    const [materialCertificates, setMaterialCertificates] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const getMaterialCertificates = () => {
        setLoading(true);
        api({
            method: 'get',
            url: 'extranet/materialcertificates/get',
        }).then(response => {
            setMaterialCertificates(response);
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        getMaterialCertificates();
    }, []);

    const getColumns = () => {
        const rowOnClickFunc = (row) => {
            setSelectedRow(row);
            setShowEditModal(true);
        }
        return [
            {
                id: 'name',
                Header: tr('identifier'),
                accessor: 'name',
                onClick: rowOnClickFunc,
                filterable: true,
                customFilter: {
                    type: "text",
                    placeholder: tr('identifier'),
                    fields: ["_original.name"],
                },
            },
            {
                id: 'file',
                Header: tr('file'),
                accessor: 'file',
                Cell: () => {
                    return <SvgIcon type="solid" icon="file" />
                },
                onClick: rowOnClickFunc,
            },
            {
                id: 'description',
                Header: tr('description'),
                accessor: 'description',
                onClick: rowOnClickFunc,
            },
            {
                id: 'project',
                Header: tr('project'),
                accessor: 'project.name',
                onClick: rowOnClickFunc,
                Cell: (row) => {
                    if (!row.value) return '';
                    return <div>
                        {row.value}
                        <small style={{ display: 'block' }}>
                            {row.original.project.project_code}
                        </small>
                    </div>
                },
                filterable: true,
                customFilter: {
                    type: "text",
                    placeholder: tr('project'),
                    fields: ["_original.project.name", "_original.project.project_code"],
                }
            },
            {
                id: 'component',
                Header: tr('storage_component'),
                accessor: 'component.name',
                onClick: rowOnClickFunc,
                Cell: (row) => {
                    if (!row.value) return '';
                    return <div>
                        {row.value}
                        <small style={{ display: 'block' }}>
                            {row.original.component.code}
                        </small>
                    </div>
                },
                filterable: true,
                customFilter: {
                    type: "text",
                    placeholder: tr('storage_component'),
                    fields: ["_original.component.name", "_original.component.code", "_original.component.identifiers.*.pivot.value"],
                }
            },
            {
                id: 'created_at',
                Header: tr('created_at'),
                accessor: 'created_at',
                Cell: (row) => {
                    return new Date(row.value).toLocaleDateString();
                },
                onClick: rowOnClickFunc,
            }
        ];
    }

    return (
        <div id='materialCertificates'>
            <div className='apBox'>
                <div className="apBoxHeader">
                    <h1>{tr('material_certificates')}</h1>
                    <p>{tr('material_certificates_manage')}</p>
                </div>
                <div className='padding'>
                    <ApReactTable
                        columns={getColumns()}
                        data={materialCertificates}
                        loading={loading}
                    />
                </div>
            </div>
            <MaterialCertificateEdit
                show={showEditModal}
                onClose={(newEntry = null) => {
                    setShowEditModal(false);
                    setSelectedRow(null);
                    if (newEntry) {
                        setMaterialCertificates([...materialCertificates, newEntry]);
                    }
                }}
                selectedRow={selectedRow}
            />
        </div>
    );
};

export default MaterialCertificates;